import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const getFingerprint = async (): Promise<string> => {
    try {
        // Load the FingerprintJS agent
        const fp = await FingerprintJS.load();

        // Get the visitor identifier
        const result = await fp.get();

        // Return the visitor ID
        return result.visitorId;
    } catch (e) {
        console.error('Error in fingerprint generation:', e);
        return '';
    }
};
