import TextInput from '@app/components/common/input/TextInput';
import Button from '@app/components/common/button';
import { ChangeEvent, FormEvent, useEffect, useMemo, useState } from 'react';
import { LinkDetails, LinkExistRequestBody } from '@app/store/formUrl/types';
import { useCustomizeFormLinkMutation, useLinkExistMutation } from '@app/store/formUrl/api';
import { toast } from 'react-toastify';
import InfoIcon from '@app/components/icons/info';
import { isValidFormUrl, isValidRelativeURL } from '@app/utils/validationUtils';
import { debounce, isEmpty } from 'lodash';
import Check from '@app/components/icons/check';
import { ToastId } from '@app/constants/toast-id';
import { useModal } from '../../modal-views/context';
import cn from 'classnames';
import { useGetUserStatusQuery } from '@app/store/users/api';

interface CustomizeUrlFormProps {
    fingerprint: string;
}

export default function CustomizeUrlForm({ fingerprint }: CustomizeUrlFormProps) {
    const [destinationUrl, setDestinationUrl] = useState<string | null>(null);
    const [mappedPath, setMappedPath] = useState<string | null>(null);
    const [isLinkError, setLinkError] = useState(false);
    const [isInvalidUrl, setInvalidUrl] = useState(false);
    const { openModal } = useModal();
    const [linkExist] = useLinkExistMutation();
    const [customizeLink, { isLoading }] = useCustomizeFormLinkMutation();
    const { data: user, isError } = useGetUserStatusQuery();
    const [linkMessage, setLinkMessage] = useState('');
    const [mappedDomain, setMappedDomain] = useState<string>('');
    const [fingerPrint, setFingerPrint] = useState<string | null>(fingerprint);
    const [protocol, setProtocol] = useState<string>('https:');

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setMappedDomain(window.location.host);
            setProtocol(window.location.protocol);
        }
    }, []);

    useEffect(() => {
        if (user) {
            setFingerPrint(null);
        } else {
            setFingerPrint(fingerprint);
        }
    }, [user, isError]);

    const handleDestinationUrlOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!isValidFormUrl(e.target.value)) {
            setInvalidUrl(true);
            return;
        }
        setDestinationUrl(e.target.value);
        setInvalidUrl(false);
    };

    const handleMappedPathOnChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!isValidRelativeURL(e.target.value)) {
            setLinkMessage("Following characters are not supported on link: space, '/'");
            setLinkError(true);
            setMappedPath(e.target.value);
            return;
        }

        const linkExistBody: LinkExistRequestBody = {
            mappedDomain: window.location.host,
            mappedPath: e.target.value
        };
        const response = await linkExist(linkExistBody);

        if ('error' in response) {
            // url not found
            setLinkError(false);
            setLinkMessage('This URL is available');
        } else {
            // url already exist
            setLinkError(true);
            setLinkMessage('This URL has already been taken. Please choose a different URL!');
        }
        setMappedPath(e.target.value);
    };

    const debouncedMappedPath = useMemo(() => {
        return debounce(handleMappedPathOnChange, 500);
    }, []);

    const handleOnFormSubmit = async (e: FormEvent<HTMLElement>) => {
        e.preventDefault();
        if (destinationUrl && mappedPath) {
            const customizeLinkBody: LinkDetails = {
                destinationUrl,
                mappedDomain,
                mappedPath,
                browserFingerprint: fingerPrint
            };
            const response = await customizeLink(customizeLinkBody);
            if ('data' in response) {
                openModal('SUCCESS_MODAL', { mappedUrl: `https://${mappedDomain}/to/${mappedPath}` });
                toast.success('Your new link has been created!');
            } else {
                toast.error('Fail to customize link', { toastId: ToastId.ERROR_TOAST });
            }
        }
    };

    return (
        <form onSubmit={handleOnFormSubmit} id="form" className="flex w-full max-w-[734px] flex-col items-center gap-8 text-start">
            <div className="flex w-full flex-col gap-2" id="form link ">
                <div className="flex items-center justify-between">
                    <p className="body1-responsive font-semibold">Form link</p>
                    <InfoIcon className="cursor-pointer text-blue-400" onClick={() => openModal('COPY_GOOGLE_FORM_URL_DEMO')} />
                </div>
                <TextInput onChange={handleDestinationUrlOnChange} type="text" placeholder="https://docs.google.com/forms/d/e/1FAIpQL2DbXdhqfI-w/viewform?usp=sf_link" className="border-border-primary overflow-ellipsis rounded-[12px] border shadow-md" />
                {isInvalidUrl && (
                    <div className="flex gap-2">
                        <InfoIcon className="text-error-600" />
                        <p className="body3-responsive flex gap-1 font-medium text-error-600">
                            Invalid URL. Please enter a valid Form URL.
                            <span onClick={() => openModal('COPY_GOOGLE_FORM_URL_DEMO')} className="cursor-pointer text-secondary-500">
                                Learn how to find correct Google link.
                            </span>
                        </p>
                    </div>
                )}
            </div>
            <div id="customize link" className="flex w-full flex-col gap-2">
                <p className="body1-responsive font-semibold">Customize form link</p>
                <div className="flex max-w-full items-center rounded-[12px] shadow-md">
                    <p className="body1-responsive w-auto flex-shrink-0 rounded-l-[12px] bg-neutral-200 px-3 py-4 sm:px-5 sm:py-6">
                        {protocol}
                        {'//'}
                        {mappedDomain}/to/
                    </p>
                    <TextInput onChange={debouncedMappedPath} placeholder="my-awesome-form" className="flex-grow rounded-r-[12px] border-none pl-2" type="text" />
                </div>
                {mappedPath && !isEmpty(mappedPath) && (
                    <div className="mt-2 flex items-center gap-2">
                        {isLinkError ? <InfoIcon className="text-error-600" /> : <Check className="text-success-600" />}
                        <p className={cn('body3', isLinkError ? 'text-error-600' : 'text-success-600')}>{linkMessage}</p>
                    </div>
                )}
            </div>
            <Button isLoading={isLoading} disabled={isInvalidUrl || isLinkError || isEmpty(mappedPath) || isEmpty(destinationUrl)}>
                Customize
            </Button>
        </form>
    );
}
