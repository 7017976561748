import React, { useState } from 'react';
import LogoIcon from '@app/components/icons/logo';
import { useScrollContext } from '@app/context/scroll-context';
import AnchorLink from '@app/components/common/links/anchor-link';
import { useRouter } from 'next/router';
import Button from '@app/components/common/button';
import environments from '@app/configs/environments';
import Burger from '@app/components/icons/burger';
import { useGetUserStatusQuery } from '@app/store/users/api';

function Navbar() {
    const { scrollToSection, howItWorksRef, featuresRef, faqsRef } = useScrollContext();
    const [isMenuVisible, setMenuVisible] = useState(false);
    const router = useRouter();
    const { data: user, isError } = useGetUserStatusQuery();
    const handleNavigation = (ref: React.RefObject<HTMLDivElement>) => {
        if (router.query.page !== undefined) {
            router.replace('/');
        }
        scrollToSection(ref);
        if (isMenuVisible) {
            setMenuVisible(false);
        }
    };
    return (
        <nav className="border-border-primary relative top-0 !z-30 flex h-[68px] w-full flex-col items-center justify-center border-b bg-white-1 px-6 sm:px-10">
            <div className="flex w-full max-w-[1440px] items-center justify-between gap-10">
                <AnchorLink href="/" aria-label="navigate to landing page">
                    <LogoIcon />
                </AnchorLink>
                <div className="body3-responsive hidden items-center gap-10 text-text-primary sm:flex">
                    <p className="cursor-pointer" onClick={() => handleNavigation(howItWorksRef)}>
                        How It Works
                    </p>
                    <p className="cursor-pointer" onClick={() => handleNavigation(featuresRef)}>
                        Features
                    </p>
                    <p className="cursor-pointer" onClick={() => handleNavigation(faqsRef)}>
                        FAQs
                    </p>
                    <AnchorLink href={environments.FEEDBACK_FORM || ''} target="_blank">
                        Feedback
                    </AnchorLink>
                    {user ? (
                        <Button className="my-5 h-11 w-[100px] text-sm sm:my-8">
                            <AnchorLink href={'/dashboard'}>Dashboard</AnchorLink>
                        </Button>
                    ) : (
                        <Button className="my-5 h-11 w-[100px] text-sm sm:my-8">
                            <AnchorLink href="/login">Sign in</AnchorLink>
                        </Button>
                    )}
                </div>
                {/* <!-- mobile button goes here --> */}
                <div className="flex items-center sm:hidden">
                    <Burger
                        className="h-6 w-6 cursor-pointer"
                        onClick={() => {
                            setMenuVisible(!isMenuVisible);
                        }}
                    />
                </div>
            </div>
            {/* <!-- mobile menu --> */}
            {isMenuVisible && (
                <div className="absolute top-[68px] flex w-full flex-col items-center gap-8 bg-white px-5 py-6 sm:hidden">
                    <p className="cursor-pointer" onClick={() => handleNavigation(howItWorksRef)}>
                        How It Works
                    </p>
                    <p className="cursor-pointer" onClick={() => handleNavigation(featuresRef)}>
                        Features
                    </p>
                    <p className="cursor-pointer" onClick={() => handleNavigation(faqsRef)}>
                        FAQs
                    </p>
                    <AnchorLink href={environments.FEEDBACK_FORM || ''} target="_blank">
                        Feedback
                    </AnchorLink>
                    {user ? (
                        <Button className="my-5 h-11 w-[100px] text-sm sm:my-8">
                            <AnchorLink href={'/dashboard'}>Dashboard</AnchorLink>
                        </Button>
                    ) : (
                        <Button className="my-5 h-11 w-[100px] text-sm sm:my-8">
                            <AnchorLink href="/login">Sign in</AnchorLink>
                        </Button>
                    )}
                </div>
            )}
        </nav>
    );
}

export default Navbar;
