export default function CardViewSkeleton() {
    return (
        <>
            <div className="mb-4 flex w-full animate-pulse items-center justify-between">
                <div className="h-6 w-24 rounded bg-gray-200"></div>
                <div className="h-8 w-32 rounded bg-[#f5d7d5]"></div>
            </div>
            <div className="w-full text-left">
                {[...Array(3)].map((_, index) => (
                    <div key={index} className={`mb-4 flex w-full animate-pulse flex-col items-center justify-between gap-4 rounded-lg border bg-white p-4 shadow-lg md:flex-row`}>
                        <div className="flex w-full min-w-0 items-center gap-4">
                            <div className="flex-shrink-0 rounded-full border-2 border-neutral-300 bg-gray-200 p-2">
                                <div className="h-7 w-7 bg-gray-300"></div>
                            </div>
                            <div className="flex w-full min-w-0 flex-col gap-1 overflow-hidden text-left">
                                <div className="h-4 w-3/4 rounded bg-gray-200 sm:h-5"></div>
                                <div className="h-3 w-1/2 rounded bg-gray-200 sm:h-4"></div>
                            </div>
                        </div>
                        <div className="mt-2 flex w-full items-center justify-end gap-2 sm:ml-2 sm:mt-0 md:w-auto">
                            <div className="h-9 w-20 rounded-lg bg-gray-200"></div>

                            <div className="h-8 w-8 rounded-md bg-gray-200"></div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
