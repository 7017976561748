import { customizeLink } from '@app/constants';
import CustomizeUrlForm from '../ui/forms/customize-url-form';
import { useScrollContext } from '@app/context/scroll-context';
import { useEffect, useState } from 'react';
import CustomizeUrlCardList from '../ui/customize-url-form-preview-list';
import { getFingerprint } from '@app/utils/browserFingerprint';

export default function CustomizeUrl() {
    const { customizeLinkRef } = useScrollContext();

    const [fingerprint, setFingerPrint] = useState('');

    useEffect(() => {
        const loadFingerprint = async () => {
            const visitorId = await getFingerprint();
            setFingerPrint(visitorId);
        };

        loadFingerprint();
    }, []);

    return (
        <div ref={customizeLinkRef} id="customize-url" className="relative flex w-full flex-col items-center justify-center py-[60px] text-center sm:py-[120px]">
            <div className="custom-gradient-blue absolute right-0 top-1/2 h-full w-full"></div>
            <div className="custom-gradient-blue absolute -top-60 left-0 h-full w-full"></div>
            <p className="h2-responsive text-text-primary">{customizeLink.title}</p>
            <p className="body1-responsive mb-8 max-w-[700px] text-text-secondary sm:mb-14">{customizeLink.description}</p>
            {fingerprint && (
                <>
                    <CustomizeUrlForm fingerprint={fingerprint} />
                    <CustomizeUrlCardList fingerprint={fingerprint} />
                </>
            )}
        </div>
    );
}
