import { useGetLinksQuery } from '@app/store/formUrl/api';
import CustomizeUrlCard from './cards/customize-url-card';
import CardViewSkeleton from '@app/components/ui/cards/card-view-skeleton';
import React from 'react';

interface CustomizeUrlCardListProps {
    fingerprint?: string;
}

export default function CustomizeUrlCardList({ fingerprint }: CustomizeUrlCardListProps) {
    const { data, isLoading } = useGetLinksQuery({ fingerprint: fingerprint || '', queryTag: '' });
    if (isLoading) {
        return (
            <div className="h-[200px] w-full">
                <CardViewSkeleton />
            </div>
        );
    }

    if (data) {
        return (
            <div className="mt-9 flex w-full flex-col items-center gap-5 sm:mt-14">
                {data.map((linkDetail) => {
                    return <CustomizeUrlCard key={linkDetail.id} {...linkDetail} isLandingPage={true} />;
                })}
            </div>
        );
    }
}
